import React from 'react'
import { graphql, Link } from 'gatsby'

import classnames from 'classnames'
import { Container, Row, Col, Button } from 'reactstrap'

import Layout from '../components/layout'

import Seo from '../components/shared/Seo'
import Icons from '../components/shared/Icons'
import Legal from '../components/shared/Legal'
import Slugify from '../components/sluggify'

export default class ProteoLitePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { input: '' }
  }
  render() {
    const {
      id,
      title,
      text,
      image,
      overviewTitle,
      overviewText,
      howItWorksTitle,
      hiwImage,
    } = this.props.data.page
    return (
      <Layout>
        <Seo {...this.props.data.seo} />
        <div className="bg-world">
          <Container className="py-4 py-md-5">
            <Row className="mb-3 align-items-center">
              <Col className="text-center" md="12">
                {' '}
                <h2 className="mb-5 text-gold">{title}</h2>
              </Col>
              <Col md="6" className="d-flex flex-column justify-content-end">
                <div
                  className="text-white text-justify"
                  dangerouslySetInnerHTML={{ __html: text.g.html }}
                />
                <Row>
                  <Col md="7">
                    {' '}
                    <div>
                      <Button
                        style={{ height: '60px', lineHeight: 1 }}
                        size="lg"
                        tag={Link}
                        to="/contact"
                        state={{
                          selected: 'Request Demo',
                          email: this.state.input,
                        }}
                        className="w-100 text-white py-3  mt-3 text-center   primary-btn-gradient"
                      >
                        Request Demo
                      </Button>
                    </div>
                    <Legal dark className="text-center pb-4 pb-md-0" />
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <img
                  alt=""
                  src={image.file.url}
                  alt={image.title}
                  className="w-100"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row className="py-5">
              <Col
                md="12"
                className="d-flex  flex-column justify-content-center"
              >
                <h3 className="text-center text-md-left text-primary mb-4">
                  {overviewTitle}
                </h3>
                <div
                  className="text-justify"
                  dangerouslySetInnerHTML={{ __html: overviewText.g.html }}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div id="HIW" className="pb-5">
          <Container>
            <h3 className="text-primary mb-4">{howItWorksTitle}</h3>
            <img
              alt=""
              className="w-100"
              src={hiwImage.file.url}
              alt={hiwImage.title}
            />
          </Container>
        </div>
        <Icons />
      </Layout>
    )
  }
}

export const query = graphql`
  {
    seo: contentfulProteoFleetPage {
      title: pageTitle
      description
      keywords
    }
    page: contentfulProteoFleetPage {
      id
      title
      text {
        g: childMarkdownRemark {
          html
        }
      }
      image {
        title
        description
        description
        file {
          url
        }
      }
      overviewTitle
      overviewText {
        g: childMarkdownRemark {
          html
        }
      }
      howItWorksTitle
      hiwImage {
        title

        description
        file {
          url
        }
      }
    }
  }
`
